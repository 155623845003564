﻿body {
    background-color: $background-body;
}

.main-left {
    @extend .col-sm-4;
    @extend .col-md-3;
    @extend .col-lg-2;
    @extend .px-2;
    @extend .pb-4;
    @extend .d-none;
    @extend .d-sm-block;
    background-color: $background-left;

    .left-bbb {
        @extend .mt-4;
        text-align: center;
    }

    .left-image {
        @extend .my-3;
        text-align: center;
        img{
            max-width:100%;
            height:auto;
        }
    }
}

.main-right {
    @extend .col-sm-8;
    @extend .col-md-9;
    @extend .col-lg-10;
}

.main-full {
    @extend .col;
}

.main-right, .main-full {
    @extend .py-4;
    background-color:#fff;

    h1 {
        @extend .pb-2;
        @extend .mb-3;
        @extend .border-bottom;
    }

    h2 {
        @extend .pb-2;
        @extend .mb-3;
        @extend .border-bottom;
    }
}

footer {
    background-color: $background-body;
    text-align: center;
    @extend .py-1;
    font-size:12px;
}

.nav-left {
    background-color: $background-navleft;
    border-top: solid 1px #dddddd;
    @extend .my-2;

    h3 {
        font-weight:bold;
        font-size: 1rem;
            @extend .px-2;
    }

    .nav-item {
        .nav-link {
            @extend .py-1;
            @extend .px-2;
            color: #4a4a4a;

            &:hover {
                background-color: #e4e4e4;
            }
        }

        border-bottom: solid 1px #dddddd;
    }
}

.bg-header {
    background-color: $background-header !important;
}

.header-standard {
    background-color: $background-header;
    @extend .py-3;

    .header-standard-button {
        img {
            height: 25px;
        }

        &.first {
            margin-bottom: 5px;
        }

        text-align: right;
    }

    border-bottom: solid 1px lighten($background-header,20%);
}

.header-mobile {
    @extend .py-2;
    background-color: #fff;

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .bottombutton {
        @extend .mt-1;
    }
}
/* forms */
legend {
    @extend .mb-3;
    @extend .pb-1;
    color: #333;
    border-bottom: 1px solid #e5e5e5
}


/* pricing table */
.pricetablefull .pricetable {
    width: 100%;
    margin-bottom: 20px;
}

.pricetable {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    @extend .mb-3;

    td, th {
        padding: 4px 8px;
        border: solid 1px #fff;
    }

    th {
        background-color: #6495ED;
        color: white;
        font-weight: bold;
    }

    td {
        background-color: #eeeeee;
    }

    .register {
        width: 105px;
        padding: 4px 0;
        text-align: center;
    }

    .price {
        text-align: center;
        width: 90px;
    }

    .name {
        font-weight: bold;
    }
}

/* Right hand content */
.bluebox {
    background-color: $background-box;
    border-top: solid 1px $background-header;
    border-bottom: solid 1px $background-header;
    padding: 9px;
    font-size: 13px;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    h4 {
        margin-top: 0;
        font-size: 14px;
        color: #254689;
        font-weight: bold;
    }

    ul {
        padding-left: 20px;
    }

    &.img {
        border: none;
        padding: 0;
        background-color: #fff;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.white {
        border: none;
        padding: 0;
        background-color: #fff;
    }

    .socialshare {
        width: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    &.asseenin {
        h4 {
            text-align: center;
        }

        div img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }
    }
}

.testimonialBox {
    border-top: solid 2px #9FB6CD;
    border-bottom: solid 2px #9FB6CD;

    @extend .mb-3;
    @extend .p-3;
    background-color: #C6E2FF;

    .testimonialText {
    }

    .testimonialSig {
        @extend .mt-1;
        text-align: right;
        color: #666;
    }
}

// cart

.regsum {
    width: 100%;
    border-collapse: collapse;

    .regsumdesc {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        width: 75%;
    }

    .regsumprice {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        width: 25%;
    }

    .regsumdescfoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        border-top: solid 1px black;
        font-weight: bold;
    }

    .regsumpricefoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        border-top: solid 1px black;
        font-weight: bold;
    }
}
